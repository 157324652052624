import { on_ready, queue_tasks, is_elementor_edit } from 'js/utils';
import { retina_init, dynamic_load_trigger } from 'js/functions';
import { toggle_height_init } from 'js/toggle_height_init';
import header_sticky_init from 'modules/header/header_sticky_init';
import forms_init from 'modules/forms/forms';
import carousels_init from 'modules/carousel/carousel';
import sticky_footer_cta_init from 'modules/footer/sticky_footer_cta/init';
import nav_menu_init from 'modules/menu/nav/nav';

// load global styles
import './main.scss';

on_ready(()=>{

    on_elementor_edit_screen();
    
    if( is_elementor_edit() ) {
        return;
    }
    
    queue_tasks([
        header_sticky_init,
        nav_menu_init,
        forms_init, // forms ui + gravityforms customizations
        carousels_init, // swiper
        retina_init, // load @2x images
        toggle_height_init, // toggle element height from 0 to default - for animating height
        on_mobile,
        populate_gravity_resource_popup,
    ]);

    dynamic_load_init();
    
});

function on_elementor_edit_screen(){
    sticky_footer_cta_init();
}

function on_mobile(){
    if( window.innerWidth > 768 ) return;
    close_footer_dropdowns();
}

function close_footer_dropdowns(){
    document.querySelectorAll('.elementor-location-footer .ff_toggle_height_trigger.open').forEach(dropdown=>{
        dropdown.click();
    })
}

function dynamic_load_init(){

    // search popup: remove if not used
    dynamic_load_trigger({
        triggers: '.search_popup_open',
        event: 'click',
        load: ()=>{
            import('modules/search/search_popup/search_popup.scss');
            import('modules/search/search_popup/search_popup');
        }
    })

    // slide/mobile menu: remove if not used
    dynamic_load_trigger({
        triggers: '.slide_menu_open',
        event: 'click',
        load: ()=>{
            import('modules/menu/slide_menu/slide_menu.scss');
            import('modules/menu/slide_menu/slide_menu');
        }
    })

    // wishlist & compare: remove if not used
    if( document.querySelector('.compare_count') ||
        document.querySelector('.wishlist_count') ) {
        import('modules/save_items/init');
    }

    // cookie consent
    // if( !getCookie('ff_cookie_consent') ) {
    //     import('modules/cookie_consent/simple/simple')
    // }
}

function populate_gravity_resource_popup() {
    document.querySelectorAll(".resource-download-button").forEach(button => {
        button.addEventListener("click", function () {
            let resourceName = this.getAttribute("data-resource");

            if (!resourceName) return;

            let popupObserver = new MutationObserver((mutations, observer) => {
                let popup = document.querySelector('.elementor-popup-modal'); 

                if (popup && popup.style.display !== "none") {
                    let form = popup.querySelector('form');

                    if (form) {
                        let resourceField = form.querySelector("input[name='input_11'], #input_7_11");

                        if (resourceField) {
                            resourceField.value = resourceName;
                            resourceField.dispatchEvent(new Event('input', { bubbles: true }));
                            observer.disconnect(); 
                        }
                    }
                }
            });

            popupObserver.observe(document.body, { childList: true, subtree: true });

            setTimeout(() => popupObserver.disconnect(), 5000);
        });
    });
}